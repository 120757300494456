<template>
  <div class="sub-cont">
    <div class="complete-box">
      <div class="check-ico"><i class="ico el-icon-check"></i></div>
      <p>
        일자리 등록이 완료되었습니다. <br />구인공고는 10분이내에 표시가 됩니다.
        <br />감사합니다. <br />
      </p>
      <p>{{ sec }}초 후 홈으로 이동합니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sec: 5,
      intervalId: null,
    }
  },
  created() {
    if (this.intervalId) this.intervalId = null
    this.intervalId = setInterval(() => {
      this.sec--
      if (this.sec === 0) this.$router.push('/')
    }, 1000)
  },
}
</script>

<style scoped>
.complete-box {
  height: 515px;
}
</style>
